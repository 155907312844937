<template>
    <div class="custom-input">
        <v-textarea
            :label="label"
            persistent-placeholder
            outlined
            :dense="dense"
            :value="fieldValue" 
            :disabled="disabled" 
            :readonly="isReadOnly"
            :hide-details="hideDetails"
            :placeholder="placeholder" 
            :rules="[v => required ? ($validate.required(v) || '此位置不能留空') : true]" 
            :class="inputClass"
            @input="updateValue($event)"
            :rows="rows"
        ></v-textarea>
    </div>
</template>

<script>

export default {
    name: 'FormTextarea',
    props: {
        label: {
            type: String,
            required: false,
            default: ''
        },
        fieldValue: {
            type: String | Number,
            required: true,
            default: ''
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        readonly: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: true
        },
        customInputClass: {
            type: String,
            required: false,
            default: ''
        },
        dispatchUpdateOnChange: {
            type: Boolean,
            required: false,
            default: false
        },
        rows: {
            type: Number,
            required: false,
            default: 2,
        }
    },
    computed: {
        isReadOnly() {
            return this.readonly || this.$store.getters.isLoading
        },
        inputClass() {
            let classObj = {
                'pointer-none': this.$store.getters.isLoading,
            };
            if (this.$validate.DataValid(this.customInputClass)) {
                const arr = this.customInputClass.split(' ');
                arr.forEach(name => {
                    if (this.$validate.DataValid(name.trim())) {
                        classObj[name.trim()] = true;
                    }
                })
            }

            return classObj;
        }
    },
    data: () => ({}),
    methods: {
        updateValue(value) {
            this.searchText = value;
            this.$emit('update:fieldValue', value);
            this.$emit('input-value', value);
            if (this.dispatchUpdateOnChange === true) {
                this.$store.dispatch('setDataIsUpdated', true);
            }
        },
    }
}
</script>