<template>
  <div>
    <v-row>
      <v-col v-if="noTextUploadBtn" cols="3">
        <v-btn depressed @click="_triggerUploadFile()" :disabled="disableUpload" :loading="uploading"
          class="no-text-upload-btn">
          <v-icon color="#5e566924">{{ mdiPlus }}</v-icon>
        </v-btn>
      </v-col>
      <v-col 
        :cols="noTextUploadBtn ? '9' : '12'"
      >
        <div class="image-upload-display">
          <v-img v-if="imgList.length <= 0" 
            :src="require('@/assets/empty-image.svg')" 
            :max-height="150"
            :max-width="250"
          ></v-img>
          <template v-else>
            <div v-for="(img, index) of imgList" :key="index">
              <template v-if="dataType === 'string'">
                <v-img class="preview-img" :src="getImage(img)" :max-height="150" :max-width="250" @click="previewImg(index)"></v-img>
              </template>
              <template v-if="dataType === 'object' && $validate.DataValid(img.image)">
                <span class="img-des" v-if="$validate.DataValid(img.remark)">{{ img.remark }}</span>
                <v-img class="preview-img" :src="getImage(img.image)" :max-height="150" :max-width="250" @click="previewImg(index)"></v-img>
              </template>
              <div class="d-flex justify-space-between mt-1">
                <v-btn depressed small text color="error" @click="removeImage(index)" :disabled="disableUpload">刪除</v-btn>
                <v-btn v-if="dataType === 'object' && $validate.DataValid(img.image)" 
                  depressed small text color="success" @click="addImageDes(index)" :disabled="disableUpload">新增文字</v-btn>
              </div>

            </div>
          </template>
        </div>
      </v-col>
      <v-col 
        cols="12" 
        v-show="!noTextUploadBtn"
      >
        <v-file-input 
          v-show="false" 
          :id="fileInputKey" 
          :accept="fileFormatStr"
          @change="getUploadFile($event, fileInputKey)" 
          :disabled="uploading || disableUpload"
        ></v-file-input>
        <v-btn v-if="!noTextUploadBtn" color="primary" depressed @click="_triggerUploadFile()" :disabled="uploading" :loading="uploading">
          {{ uploadText }}
        </v-btn>
      </v-col>
    </v-row>

    <Dialog
      :openDialog="addDesDialogOpen"
      title="新增文字"
      enableScroll
      @close="saveImageDes($event)"
    >
      <FormTextarea
        :fieldValue.sync="newDes"
      ></FormTextarea>

    </Dialog>
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js';
import Dialog from './layout/Dialog.vue';
import FormTextarea from './form/FormTextarea.vue';

export default {
  name: 'ImageUploader',
  components: {
    Dialog,
    FormTextarea
  },
  props: {
    fileInputKey: {
      type: String,
      default: 'upload_file',
      required: true,
    },
    imgList: {
      type: Array,
      default: () => [], // Array<string> | Array<{image: string, remark: string}>
      required: true,
    },
    dataType: {
      type: String,
      default: 'string', // 'string' | 'object'
    },
    singleImage: {
      type: Boolean,
      default: false,
    },
    uploadText: {
      type: String,
      default: '上載圖片',
    },
    acceptFormat: {
      type: String,
      default: 'image',
      required: true,
    },
    disableUpload: {
      type: Boolean,
      default: false,
    },
    noTextUploadBtn: {
      type: Boolean,
      default: false,
    },
    dispatchUpdateOnChange: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    uploading() {
      return this.$store.getters.isUploading
    },
    fileFormatStr() {
      return this.$common.getAcceptFileFormat(this.acceptFormat).formatStr;
    },
    fileFormatArr() {
      return this.$common.getAcceptFileFormat(this.acceptFormat).formatArr;
    }
  },
  data: () => ({
    mdiPlus,
    addDesDialogOpen: false,
    newDes: '',
    addDesIndex: -1,
  }),
  methods: {
    _triggerUploadFile() {
      if (this.$store.getters.isLoading || this.$store.getters.uploading) {
          this.$store.dispatch('toggleSnack', {
              show: true,
              message: 'processing',
              type: 'error',
              refresh: false,
              redirect: ''
          });
          return;
      }
      if (this.$validate.DataValid(this.fileInputKey) && document.getElementById(this.fileInputKey)) {
        document.getElementById(this.fileInputKey).click();
      }
    },
    async getUploadFile(file) {
      if (file !== undefined && file !== null) {
        const check = this.$validate.validateFileInput(file, this.fileFormatArr);
        if (this.$validate.DataValid(check)) {
          this.$store.dispatch('toggleSnack', {
            show: true,
            message: check,
            type: 'error',
            refresh: false,
            redirect: '',
          })
        } else {
          this.$store.dispatch('setUploadLoading', true);

          const reader = new FileReader();
          reader.onload = async (event) => {
            const fileType = file.type.split('/')[1];
            const payload = {
              upload_data: event.target.result,
              upload_file_type: fileType
            }

            try {
              const data = await this.$Fetcher.UploadFile(payload);
              this.$store.dispatch('toggleSnack', {
                show: true,
                message: '上傳成功',
                type: 'success',
                refresh: false,
                redirect: '',
              })

              let fileList = [];
              if (!this.singleImage) {
                fileList = [...this.imgList];
              }

              if (this.dataType === 'object') {
                fileList.push({
                  image: data,
                  remark: '',
                });
              } else {
                fileList.push(data);
              }
              this.$emit('update:imgList', fileList);
              if (this.dispatchUpdateOnChange === true) {
                this.$store.dispatch('setDataIsUpdated', true);
              }
            } catch (err) {
              this.$common.error(err);
              this.$store.dispatch('toggleSnack', {
                show: true,
                message: '上傳失敗',
                type: 'error',
                refresh: false,
                redirect: '',
              })
            } finally {
              this.$store.dispatch('setUploadLoading', false);
            }
          }
          reader.readAsDataURL(file);
        }
      }
    },
    getImage(img) {
      if (img.indexOf('pdf') !== -1) {
        return require('@/assets/file-pdf-box.svg')
      } else if (img.indexOf('mp4') !== -1 || img.indexOf('webm') !== -1) {
        return require('@/assets/video.svg')
      }

      return this.$mediaPath + img;
    },
    previewImg(index) {
      if (this.$validate.DataValid(this.imgList[index])) {
        if (typeof this.imgList[index] === 'string') {
          window.open(this.$mediaPath + this.imgList[index]);
          return;
        } else if (typeof this.imgList[index] === 'object' && this.$validate.DataValid(this.imgList[index].image)) {
          window.open(this.$mediaPath + this.imgList[index].image);
          return;
        }
      }

      this.$store.dispatch('toggleSnack', {
        show: true,
        message: '檔案不存在',
        type: 'error',
        refresh: false,
        redirect: '',
      });
    },
    removeImage(index) {
      const fileList = this.$common.duplicateData(this.imgList);
      fileList.splice(index, 1);
      this.$emit('update:imgList', fileList);

      if (this.dispatchUpdateOnChange === true) {
        this.$store.dispatch('setDataIsUpdated', true);
      }
    },
    addImageDes(index) {
      this.newDes = '';
      this.addDesIndex = -1;
      if (this.$validate.DataValid(this.imgList[index]) && typeof this.imgList[index] === 'object') {
        this.addDesIndex = index;
        if (this.$validate.DataValid(this.imgList[index].remark)) {
          this.newDes = this.imgList[index].remark;
        }

        this.addDesDialogOpen = true;
      }
    },
    saveImageDes(action) {
      if (action === true) {
        if (this.addDesIndex > -1 && this.$validate.DataValid(this.imgList[this.addDesIndex])) {
          const fileList = this.$common.duplicateData(this.imgList);
          fileList[this.addDesIndex].remark = this.newDes;
          this.$emit('update:imgList', fileList);
          if (this.dispatchUpdateOnChange === true) {
            this.$store.dispatch('setDataIsUpdated', true);
          }
        }
      }
    
      this.newDes = '';
      this.addDesIndex = -1;
      this.addDesDialogOpen = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.preview-img {
  &:hover {
    cursor: pointer;
  }
}

.image-upload-display {
  display: flex;
  overflow-x: auto;
  align-items: flex-end;

  &>div:not(:first-child) {
    margin-left: 20px;
  }

  & .img-des {
    white-space: pre-wrap;
    display: -webkit-inline-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    max-width: 250px;
    -webkit-box-orient: vertical;
  }
}

.no-text-upload-btn.v-btn {
  width: 100% !important;
  height: 150px !important;
  border: 2px solid rgba(94, 86, 105, 0.141);
  min-width: unset !important;
}
</style>
